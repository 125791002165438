import { ReactElement, Suspense, useEffect, useState } from 'react'
import { LoadingSpinner } from '../../components/shared/LoadingSpinner'
import { SiteButton, SiteButtonVariant } from '../../components/controls/SiteButton'
import { useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { addRecordToPrintQueue, fetchPrintAgents } from '../../services/printAgents'
import { PrintAgent } from '../../models/manager/printAgent'
import { Divider, message, Select, Tag } from 'antd'
import { errorHandler, isPrintAgentOnline } from '../../utils/helper'
import { useProfileContext } from '../../contexts/profileContext'
import { LocalStorageItems } from '../../constants/common'
import circleOutlinedGreen from '../../assets/images/localPrint/circleoutlined.svg'
import circleOutlinedRed from '../../assets/images/localPrint/circleoutlinedred.svg'
import { RecordDataTable } from '../../components/adminPrint/RecordDataTable'
import { countRecords } from '../../services/records'
import Search from 'antd/es/input/Search'
import { PrinterOutlined } from '@ant-design/icons'
import { PrintQueueDto } from '../../models/Dtos/PrintQueueDto'
import { EventLayout } from '../../components/layout/EventLayout'
import { fetchEvent } from '../../services/event'
import { EventData } from '../../models/manager/event'

interface printBatch {
  listId: number
  selectedRowKeys: React.Key[]
  templateId: number
}

export default function EventAdmin(): ReactElement {
  const navigate = useNavigate()
  const { getAccessTokenSilently } = useAuth0()
  const { profile } = useProfileContext()
  const [agents, setAgents] = useState<PrintAgent[]>([])
  const [selectedAgent, setSelectedAgent] = useState<PrintAgent>()
  const [masterListSelected, setMasterListSelected] = useState<EventData>()
  const [totalCount, setTotalCount] = useState(0)
  const [selectedRowsKeys, setSelectedRowsKeys] = useState<printBatch[]>([])
  const [searchTerm, setSearchTerm] = useState('')
  const [searchFilter, setSearchFilter] = useState<string>('')
  const [eventId, setEventId] = useState<number>()

  const [isLoading, setIsLoading] = useState(false)
  const [messageApi, contextHolder] = message.useMessage()

  const handleAgentChange = (value: number) => {
    localStorage.setItem(LocalStorageItems.SelectedAgentId, value.toString())
    setSelectedAgent(agents?.find((item) => item.Id === value))
  }

  const handlePrintSelected = async () => {
    if (!selectedAgent || selectedRowsKeys.length === 0) return

    const token = await getAccessTokenSilently()
    const printTasks = selectedRowsKeys.flatMap((item) =>
      item.selectedRowKeys.map((key) => {
        const printQueueItem: PrintQueueDto = {
          PrintAgentId: selectedAgent.Id,
          RecordId: Number(key),
          RecordSetId: item.listId,
          AccountGuid: profile.AccountUid,
          TemplateId: item.templateId,
        }

        return addRecordToPrintQueue(printQueueItem, token)
      })
    )

    const results = await Promise.allSettled(printTasks)
    const successfulPrints = results.filter((result) => result.status === 'fulfilled').length //double check fulfilled or fullfilled xD
    const failedPrints = results.length - successfulPrints

    messageApi.open({
      type: 'success',
      content: `${successfulPrints} records sent to print successfully.`,
      style: { marginTop: '15vh' },
    })
    if (failedPrints > 0) {
      messageApi.open({
        type: 'error',
        content: `${failedPrints} records failed to print.`,
        style: { marginTop: '15vh' },
      })
    }
  }

  const fetchData = async () => {
    setIsLoading(true)
    const token = await getAccessTokenSilently()

    const fetchFormPromise = fetchEvent(eventId ?? 0, token)
      .then(setMasterListSelected)
      .catch((error) => errorHandler(error))

    const fetchAgentsPromise = fetchPrintAgents(profile.AccountId, token)
      .then(setAgents)
      .catch((error) => {
        errorHandler(error)
        setAgents([])
      })

    await Promise.allSettled([fetchFormPromise, fetchAgentsPromise])
    setIsLoading(false)
  }

  const getTotalCount = async () => {
    try {
      const token = await getAccessTokenSilently()
      if (!masterListSelected?.RecordSets) {
        return
      }

      const countPromises = masterListSelected.RecordSets.map((rec) => countRecords(rec.Id, token))
      const counts = await Promise.all(countPromises)

      setTotalCount(counts.reduce((acc, curr) => acc + curr, 0))
    } catch (error) {
      errorHandler(error)
    }
  }

  const onSelectedRowsChange = (selectedRowKeys: React.Key[], listId: number, templateId: number) => {
    setSelectedRowsKeys((prev) => {
      const index = prev.findIndex((item) => item.listId === listId)
      if (index !== -1) {
        const newPrintBatches = [...prev]
        newPrintBatches[index] = { ...newPrintBatches[index], selectedRowKeys, templateId }
        return newPrintBatches
      }

      return [...prev, { listId, selectedRowKeys, templateId }]
    })
  }

  const handleSearch = (value: string) => {
    setSearchTerm(value)
    setSearchFilter(value)
  }

  const clearSearchInput = () => {
    handleSearch('')
    setSearchFilter('')
  }

  useEffect(() => {
    // Retrieve the value from localStorage and set it in the state
    const storedEventId = localStorage.getItem('eventId')
    if (storedEventId) {
      setEventId(parseInt(storedEventId) ?? 0)
    }
  }, [])

  useEffect(() => {
    if (eventId) {
      fetchData()
    }
  }, [eventId])

  useEffect(() => {
    if (masterListSelected) {
      getTotalCount()
    }
  }, [masterListSelected])

  useEffect(() => {
    if (agents) {
      const savedAgentId = localStorage.getItem(LocalStorageItems.SelectedAgentId)
      if (savedAgentId) {
        const agentId = parseInt(savedAgentId, 10)
        const agent = agents.find((item) => item.Id === agentId)
        if (agent) {
          setSelectedAgent(agent)
        }
      }
    }
  }, [agents])

  useEffect(() => {
    if (selectedRowsKeys.length > 0) {
      setSelectedRowsKeys((prev) => {
        const newKeys = prev.filter((batch) => batch.selectedRowKeys.length > 0)
        if (JSON.stringify(newKeys) !== JSON.stringify(prev)) {
          return newKeys
        }
        return prev
      })
    }
  }, [selectedRowsKeys])

  return (
    <EventLayout>
      {contextHolder}
      {isLoading && <LoadingSpinner isLoading={true} label="Loading..." />}
      <Suspense fallback={<LoadingSpinner isLoading={true} label="Loading..." />}>
        <div className="container mx-auto">
          <div className="flex justify-center items-center bg-SecondaryBgWhite-100 rounded-lg px-6">
            <h1 className="text-xl font-semibold text-PrimaryDarkBlue-100 flex-1">Your Event</h1>
            <SiteButton onClick={() => navigate('/events')} label={'Back'} id="close" />
          </div>
          <div className="flex-col items-center bg-SecondaryBgWhite-100 rounded-lg px-4">
            <Divider className="my-2 bg-BorderLightGrey-100" />
            <div className="flex items-center px-4 w-full justify-between">
              <div className="flex items-center justify-between w-full">
                <div className="flex items-center gap-2">
                  <h1 className="text-lg text-PrimaryDarkBlue-100 font-semibold">{masterListSelected?.Name}</h1>
                  <Tag color="blue">{totalCount}</Tag>
                </div>

                <div className="flex flex-col gap-2 ">
                  <span className="text-xs text-TextSecondaryGrey-100 font-normal pt-2">
                    Change PrintAgent <span className="text-ErrorPrimary-100">*</span>
                  </span>
                  <Select
                    className="w-full md:w-52"
                    value={selectedAgent?.Id}
                    onChange={handleAgentChange}
                    options={agents.map((agent) => ({
                      label: agent.Name,
                      value: agent.Id,
                    }))}
                  />
                  {agents && agents.length > 0 && selectedAgent && (
                    <div className="flex items-center justify-end gap-2">
                      <span className="text-xs text-TextSecondaryGrey-100 font-normal">Printer Status:</span>
                      <Tag
                        color={`${isPrintAgentOnline(selectedAgent.LastCheckedInAt) ? 'green' : 'red'}`}
                        className="flex items-center gap-2"
                      >
                        <img
                          src={
                            isPrintAgentOnline(selectedAgent.LastCheckedInAt) ? circleOutlinedGreen : circleOutlinedRed
                          }
                          alt=""
                        />
                        {isPrintAgentOnline(selectedAgent.LastCheckedInAt) ? 'Online' : 'Offline'}
                      </Tag>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Divider className="my-4 bg-BorderLightGrey-100" />
            <div className="flex justify-between items-center py-2 w-full">
              <div className="flex gap-4 items-center">
                <Search
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  id="search"
                  placeholder="Search Records"
                  onSearch={handleSearch}
                  allowClear
                  className="w-80"
                />
                <SiteButton
                  onClick={clearSearchInput}
                  size="small"
                  label={'Clear filters'}
                  id={'preview'}
                  variant={SiteButtonVariant.Secondary}
                  style={{ margin: 0 }}
                />
              </div>
              <div className="flex gap-4 items-center">
                <SiteButton
                  disabled={selectedRowsKeys.length === 0}
                  onClick={handlePrintSelected}
                  icon={<PrinterOutlined />}
                  label={'Print Selected'}
                  id={'print_selected'}
                  variant={SiteButtonVariant.Secondary}
                  style={{ margin: 0 }}
                />
              </div>
            </div>
            <div className="flex flex-col gap-2">
              {masterListSelected?.RecordSets?.map((list) => (
                <RecordDataTable
                  key={list.Id}
                  listId={list.Id}
                  masterListName={list.Name}
                  onSelectedRowsChange={(selectedRowKeys, listId, templateId) =>
                    onSelectedRowsChange(selectedRowKeys, listId, templateId)
                  }
                  searchTerm={searchFilter}
                  selectedAgent={selectedAgent}
                />
              ))}
            </div>
          </div>
        </div>
      </Suspense>
    </EventLayout>
  )
}
