import { Button, Collapse, Input, message, Select, Skeleton, Space, Table, Tag } from 'antd'
import { Key, ReactElement, ReactNode, useCallback, useEffect, useState } from 'react'
import { fetchFieldSet, getRecordsByRecordSetId } from '../../services/records'
import { FieldSet } from '../../models/manager/fieldset'
import { FieldType, STARTER_TEMPLATE_THUMBNAILS_BLOB_FOLDER } from '../../constants/common'
import { ImageCell } from '../shared/ImageCell'
import { FilterDropdownProps } from 'antd/es/table/interface'
import { EditOutlined, PrinterOutlined, SearchOutlined, UserAddOutlined } from '@ant-design/icons'
import { errorHandler, removeDiacritics } from '../../utils/helper'
import { useAuth0 } from '@auth0/auth0-react'
import { SiteButton, SiteButtonVariant } from '../controls/SiteButton'
import { Template } from '../../models/manager/template'
import { fetchTemplatesByRecordSetId } from '../../services/templates'
import { TemplateThumbnail } from '../shared/TemplateThumbnail'
import { useProfileContext } from '../../contexts/profileContext'
import { PrintAgent } from '../../models/manager/printAgent'
import { PrintQueueDto } from '../../models/Dtos/PrintQueueDto'
import { addRecordToPrintQueue } from '../../services/printAgents'
import { RecordForm } from './RecordForm'

interface Props {
  listId: number
  masterListName: string
  onSelectedRowsChange?: (selectedRowKeys: React.Key[], listId: number, templateId: number) => void
  searchTerm?: string
  selectedAgent?: PrintAgent | null
}

interface RecordsColumns {
  title: string
  dataIndex: number | string
  key: number | string
  width?: number | string
  filterDropdown?: (props: FilterDropdownProps) => ReactNode
  onFilter?: (value: string | number | boolean, record: Record<string, string>) => boolean
  filteredValue?: string[] | null
  sorter?: (a: Record<string, string>, b: Record<string, string>) => number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  render?: (record: Record<string, any>) => ReactElement
  fixed?: boolean | 'left' | 'right'
  sticky?: boolean
}

export function RecordDataTable({
  listId,
  masterListName,
  onSelectedRowsChange,
  searchTerm,
  selectedAgent,
}: Props): ReactElement {
  const { getAccessTokenSilently } = useAuth0()
  const { profile } = useProfileContext()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [recordsData, setRecordsData] = useState<Record<string, any>[]>([])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [fetchedData, setFetchedData] = useState<Record<string, any>[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [columns, setColumns] = useState<RecordsColumns[]>([])
  const [activeKey, setActiveKey] = useState<string[]>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])
  const [availableTemplates, setAvailableTemplates] = useState<Template[]>([])
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>()
  const [messageApi, contextHolder] = message.useMessage()
  const [fieldSetData, setFieldSetData] = useState<FieldSet[]>([])
  const [openDrawer, setOpenDrawer] = useState<boolean>(false)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedRowData, setSelectedRowData] = useState<Record<string, any> | null>()

  const sentToPrint = (recordId: number, recordSetId: number, templateId: number) => {
    if (recordId) {
      const printQueueItem: PrintQueueDto = {
        PrintAgentId: selectedAgent?.Id ?? 0,
        RecordSetId: recordSetId ?? 0,
        RecordId: recordId,
        AccountGuid: profile.AccountUid,
        TemplateId: templateId ?? 0,
      }

      getAccessTokenSilently().then(async (token) => {
        try {
          await addRecordToPrintQueue(printQueueItem, token)
          messageApi.open({
            type: 'success',
            content: 'Printing record',
            style: { marginTop: '15vh' },
          })
        } catch (error) {
          errorHandler(error)
          messageApi.open({
            type: 'error',
            content: 'Failed to Print, please try again',
            style: { marginTop: '15vh' },
          })
        }
      })
    }
  }

  const handlePrint = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent> | undefined, recordId: number) => {
      event?.stopPropagation()

      if (!selectedAgent || !selectedTemplate) {
        return messageApi.open({
          type: 'error',
          content: 'Print Agent and Template Design are required',
          style: { marginTop: '15vh' },
        })
      }
      sentToPrint(recordId, listId, selectedTemplate?.Id ?? 0)
    },
    [selectedAgent, selectedTemplate]
  )

  const getColumns = async () => {
    try {
      const response = await fetchFieldSet(listId, await getAccessTokenSilently())
      setFieldSetData(response)
      if (response.length > 0) {
        const dynamicColumns: RecordsColumns[] = response.map((item: FieldSet) => {
          const { Name, TypeId } = item

          let sorterFunction: ((a: Record<string, string>, b: Record<string, string>) => number) | undefined
          let renderFunction: ((a: Record<string, string>) => ReactElement) | undefined
          if (TypeId === FieldType.Date) {
            sorterFunction = (a, b) => {
              const dataIndex = item.Name
              if (a[dataIndex]) {
                return a[dataIndex].localeCompare(b[dataIndex])
              }
              return 0
            }
          }

          if (TypeId === FieldType.Image) {
            renderFunction = (record) => {
              return (
                <div className="min-w-[152px] max-w-[152px]">
                  <div className="flex justify-between items-center">
                    <ImageCell
                      showChangeButton={false}
                      record={record}
                      item={item}
                      recordSetId={listId}
                      fieldSetId={response.find((f) => f.Name == item.Name)?.Id || 0}
                      refresh={() => {}}
                    />
                  </div>
                </div>
              )
            }
          }

          return {
            title: Name,
            dataIndex: TypeId === FieldType.Image ? '' : Name,
            key: Name,
            filterSearch: true,
            width: 300,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => (
              <div style={{ padding: 8 }}>
                <Input
                  placeholder={`Search ${item.Name}`}
                  value={selectedKeys[0]}
                  onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                  onPressEnter={() => handleSearch(confirm)}
                  style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                  <Button
                    className="text-white hover:!text-white bg-credsPrimaryBlue-100 hover:bg-PrimaryDarkBlue-100 inline-flex items-center"
                    onClick={() => handleSearch(confirm)}
                    icon={<SearchOutlined className="leading-[0]" />}
                    size="small"
                    style={{ width: 90 }}
                  >
                    Search
                  </Button>
                  <Button
                    onClick={() => {
                      if (clearFilters) clearFilters()
                      handleSearch(confirm)
                    }}
                    size="small"
                    style={{ width: 90 }}
                  >
                    Reset
                  </Button>
                </Space>
              </div>
            ),
            onFilter: (value, record) => {
              const dataIndex = Name
              return record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toString().toLowerCase())
                : false
            },
            render: renderFunction,
            sorter: sorterFunction,
          }
        })

        const actionButtons: RecordsColumns = {
          key: 'actions',
          title: 'Actions',
          dataIndex: '',
          width: 100,
          fixed: 'right',
          sticky: true,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          render: (record: any) => {
            return (
              <div className="flex justify-around items-center">
                <SiteButton
                  onClick={(e) => onRowSelected(e, record)}
                  label={''}
                  id={''}
                  variant={SiteButtonVariant.Secondary}
                  icon={<EditOutlined />}
                />
                <SiteButton
                  onClick={(e) => handlePrint(e, record.__cn_id)}
                  label={'Print'}
                  id={''}
                  variant={SiteButtonVariant.Secondary}
                  icon={<PrinterOutlined />}
                />
              </div>
            )
          },
        }
        setColumns([...dynamicColumns, { ...actionButtons }])
      }
    } catch (error) {
      errorHandler(error)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onRowSelected = (event: React.MouseEvent<HTMLElement, MouseEvent> | undefined, record: any) => {
    //TODO: handle the edit logic.
    event?.stopPropagation()
    setSelectedRowData(record)
    setOpenDrawer(true)
  }

  const handleSearch = (confirm: () => void) => {
    confirm()
  }

  const onPanelChange = (keys: string | string[]) => {
    setActiveKey(typeof keys === 'string' ? [keys] : keys)
  }

  const getRecordData = async () => {
    setIsLoading(true)
    try {
      const response = await getRecordsByRecordSetId(listId, await getAccessTokenSilently())
      setRecordsData(response)
      setFetchedData(response)
      setIsLoading(false)
    } catch (error) {
      errorHandler(error)
      setIsLoading(false)
    }
  }

  const getTemplatesByListId = async () => {
    try {
      const response = await fetchTemplatesByRecordSetId(listId, await getAccessTokenSilently())
      if (response.length === 1) {
        const templateToSelect = response[0]
        setSelectedTemplate(templateToSelect)
      }
      setAvailableTemplates(response)
    } catch (error) {
      errorHandler(error)
    }
  }

  // Handle row selection changes
  const onSelectChange = (selectedKeys: Key[]) => {
    if (!selectedTemplate) {
      return messageApi.open({
        type: 'error',
        content: 'Print Template is required to select the records',
        style: { marginTop: '15vh' },
      })
    }
    setSelectedRowKeys(selectedKeys)
    onSelectedRowsChange?.(selectedKeys, listId, selectedTemplate?.Id)
  }

  const handleTemplateChange = (value: number) => {
    setSelectedTemplate(availableTemplates?.find((item) => item.Id === value) ?? null)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const handleAddNewRecord = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation()
    setSelectedRowData(null)
    setOpenDrawer(true)
  }

  const refreshList = () => {
    getRecordData()
    setSelectedRowData(null)
  }

  useEffect(() => {
    getColumns()
    getRecordData()
    getTemplatesByListId()
  }, [listId])

  useEffect(() => {
    const actionButtons: RecordsColumns = {
      key: 'actions',
      title: 'Actions',
      dataIndex: '',
      width: 100,
      fixed: 'right',
      sticky: true,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render: (record: any) => {
        return (
          <div className="flex justify-around items-center">
            <SiteButton
              onClick={(e) => onRowSelected(e, record)}
              label={''}
              id={''}
              variant={SiteButtonVariant.Secondary}
              icon={<EditOutlined />}
            />
            <SiteButton
              onClick={(e) => handlePrint(e, record.__cn_id)}
              label={'Print'}
              id={''}
              variant={SiteButtonVariant.Secondary}
              icon={<PrinterOutlined />}
            />
          </div>
        )
      },
    }
    setColumns((prevColumns) => [...prevColumns.filter((column) => column.key !== 'actions'), { ...actionButtons }])
    onSelectedRowsChange?.(selectedRowKeys, listId, selectedTemplate?.Id ?? 0)
  }, [selectedAgent, selectedTemplate])

  useEffect(() => {
    setActiveKey(recordsData?.length > 0 ? ['1'] : [])
  }, [recordsData])

  useEffect(() => {
    if (!searchTerm) return setRecordsData(fetchedData)
    const normalizedSearchTerm = removeDiacritics(searchTerm.toLowerCase())
    const filteredData = fetchedData.filter((record) =>
      Object.values(record).some((value) =>
        removeDiacritics(value.toString().toLowerCase()).includes(normalizedSearchTerm)
      )
    )
    setRecordsData(filteredData)
  }, [searchTerm, fetchedData])

  return (
    <div className="py-2">
      {contextHolder}
      <Collapse
        activeKey={activeKey}
        accordion={recordsData.length > 0}
        className="w-full"
        size="small"
        onChange={onPanelChange}
      >
        <Collapse.Panel
          extra={
            <div className="flex gap-6 items-center">
              <Button onClick={handleAddNewRecord} icon={<UserAddOutlined />} size="small">
                Add New
              </Button>

              <Select
                onClick={(e) => {
                  e.stopPropagation()
                }}
                placeholder={'Select the template'}
                className="w-full md:w-52"
                value={selectedTemplate?.Id}
                onChange={handleTemplateChange}
                options={availableTemplates?.map((template) => ({
                  label: template.Name,
                  value: template.Id,
                }))}
              />
              {selectedTemplate && (
                <div className="px-2" style={{ position: 'relative' }}>
                  <TemplateThumbnail
                    mode="row"
                    templateId={selectedTemplate?.Id ?? 0}
                    extension="png"
                    thumbnailLocation={
                      selectedTemplate?.IsLocked && selectedTemplate?.IsAvailableToAll
                        ? STARTER_TEMPLATE_THUMBNAILS_BLOB_FOLDER
                        : profile.AccountUid
                    }
                    type={selectedTemplate?.TemplateType}
                  />
                </div>
              )}
            </div>
          }
          header={
            <div className="flex items-center gap-4">
              <span className="font-semibold text-base text-TextTertiaryGrey-100">{`List: ${masterListName}`}</span>
              <Tag color="blue">{recordsData?.length}</Tag>
            </div>
          }
          key="1"
        >
          {isLoading ? (
            <Skeleton className="p-2" active title={{ width: '100%' }} paragraph={{ rows: 5, width: '100%' }} />
          ) : (
            <Table
              id="manage-list"
              rowSelection={rowSelection}
              pagination={{
                pageSizeOptions: ['10', '20', '30', '50'],
                showSizeChanger: true,
              }}
              scroll={{ x: true }}
              bordered
              rowKey={(data) => data.__cn_id}
              columns={columns}
              size="small"
              dataSource={recordsData}
              onHeaderRow={() => ({
                className:
                  'text-[12px] !text-[#7E7E7E] not-italic !font-normal tracking-wide text-right !bg-red-500 !border !border-TextTertiaryGrey-100',
              })}
            />
          )}
        </Collapse.Panel>
      </Collapse>
      <RecordForm
        fieldSetData={fieldSetData}
        masterListName={masterListName}
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        listId={listId}
        refresh={refreshList}
        selectedRowData={selectedRowData}
      />
    </div>
  )
}
