import { ReactElement } from 'react'
import { EventLayout } from '../../components/layout/EventLayout'

export default function EventKiosk(): ReactElement {
  return (
    <EventLayout>
      <div>Kiosk </div>
    </EventLayout>
  )
}
