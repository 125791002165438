import { ReactElement } from 'react'
import { EventLayout } from '../../components/layout/EventLayout'

export default function EventDashboard(): ReactElement {
  return (
    <EventLayout>
      <div className="container mx-auto">
        <div className="flex justify-center items-center bg-SecondaryBgWhite-100 rounded-lg px-2 my-3">
          <h1 className="text-xl font-semibold text-PrimaryDarkBlue-100 flex-1">Dashboard</h1>
        </div>
      </div>
    </EventLayout>
  )
}
