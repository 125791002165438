import { Fragment, ReactElement, ReactNode, useEffect, useState } from 'react'
import { AppHeader } from './AppHeader'
import { Layout, Menu, MenuProps } from 'antd'
import { Content } from 'antd/es/layout/layout'
import { useAuth0 } from '@auth0/auth0-react'
import { AuthLayout } from './Auth/AuthLayout'
import { SignupInfo } from '../shared/SignupInfo'
import { useProfileContext } from '../../contexts/profileContext'
import { PlanType } from '../../constants/common'
import { Account } from '../../models/manager/account'
import { fetchAccount, updateAccount } from '../../services/accounts'
import { errorHandler } from '../../utils/helper'
import { LeftOutlined } from '@ant-design/icons'
import { Link, useNavigate } from 'react-router-dom'
import DashboardIcon from '../../assets/images/events/dashboard.svg'
import KioskIcon from '../../assets/images/events/kiosk.svg'
import AdminIcon from '../../assets/images/events/admin.svg'
import ReportingIcon from '../../assets/images/events/reporting.svg'
import IntegrationIcon from '../../assets/images/events/integrations.svg'
import LeftArrow from '../../assets/images/events/leftArrow.svg'
import RightArrow from '../../assets/images/events/rightArrow.svg'

interface Props {
  children?: ReactNode
}

type MenuItem = Required<MenuProps>['items'][number] & { path?: string }

function getItem(
  label: ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  path?: string
): MenuItem {
  return {
    key,
    icon,
    children,
    label: path ? <Link to={path}>{label}</Link> : label,
    style: !path ? { cursor: 'default' } : undefined,
  } as MenuItem
}

const items: MenuItem[] = [
  getItem(
    <span className="font-semibold">Dashboard</span>,
    '/events/dashboard',
    <img src={DashboardIcon} alt="dashboard" />,
    undefined,
    '/events/dashboard'
  ),
  getItem(
    <span className="font-semibold">Kiosk</span>,
    '/events/kiosk',
    <img src={KioskIcon} alt="Kiosk" />,
    undefined,
    '/events/kiosk'
  ),
  getItem(
    <span className="font-semibold">Admin Lookup</span>,
    '/events/admin',
    <img src={AdminIcon} alt="Admin" />,
    undefined,
    '/events/admin'
  ),
  getItem(
    <span className="font-semibold">Reporting</span>,
    '/events/reports',
    <img src={ReportingIcon} alt="Reporting" />,
    undefined,
    '/events/reports'
  ),
  getItem(
    <span className="font-semibold">Integrations</span>,
    '/events/integrations',
    <img src={IntegrationIcon} alt="Kiosk" />,
    undefined,
    '/events/integrations'
  ),
]

export function EventLayout({ children }: Props): ReactElement {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0()
  const { profile, saveProfile } = useProfileContext()
  const { Header, Sider } = Layout
  const { pathname } = location
  const navigate = useNavigate()
  const { getAccessTokenSilently } = useAuth0()
  const [collapsed, setCollapsed] = useState(false)

  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false)

  const goToEvents = () => {
    navigate('/events')
  }

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect()
    }

    if (localStorage.getItem('p') != null) {
      if (!profile.IsProfileComplete) {
        saveProfile({ ...profile, IsProfileComplete: false })
        setIsProfileModalOpen(true)
      } else {
        saveProfile({ ...profile, IsProfileComplete: true })
      }
    }

    if (profile.IsProfileComplete) {
      if (!profile.IsPlanComplete) {
        getAccessTokenSilently().then(async (token) => {
          fetchAccount(profile.AccountId, token).then(async (userAccount) => {
            if (userAccount) {
              const accountData: Account = {
                ...userAccount,
                PlanId: PlanType.Essentials,
                PlanStartDate: new Date(),
              }
              updateAccount(accountData, await getAccessTokenSilently())
                .then(() => {
                  saveProfile({ ...profile, IsPlanComplete: true })
                })
                .catch(errorHandler)
            }
          })
        })
      }
    }
  }, [])

  const modalStyle = `mainLayout !bg-PrimaryBgWhite-100 min-h-screen`

  return (
    <Fragment>
      {!profile.IsProfileComplete ? (
        <AuthLayout>
          <SignupInfo isOpen={isProfileModalOpen} />
        </AuthLayout>
      ) : (
        <Layout className={modalStyle}>
          <Header>
            <AppHeader />
            <div className="h-[10px]" />
          </Header>
          <Layout className="mt-2 h-full">
            <Sider
              trigger={
                <div className="flex justify-end mr-2">
                  {collapsed && <img src={RightArrow} className="transition-all ease-in" />}
                  {!collapsed && <img src={LeftArrow} className="transition-all ease-in" />}
                </div>
              }
              theme="light"
              collapsible
              collapsed={collapsed}
              onCollapse={(value) => setCollapsed(value)}
              width={200}
              style={{ overflow: 'auto', height: 'auto', position: 'sticky', left: 0, top: 0, bottom: 0 }}
            >
              <div className={`mt-6 ${collapsed ? 'ml-7' : 'ml-6'}  gap-2 cursor-pointer`} onClick={goToEvents}>
                <LeftOutlined className="transition-all ease-in text-lg" />
                {!collapsed && <span className="text-lg font-normal"> Back</span>}
              </div>
              <Menu
                activeKey="pathname"
                className="mt-4"
                selectedKeys={[pathname]}
                defaultSelectedKeys={[pathname]}
                theme="light"
                mode="inline"
                items={items}
              />
            </Sider>
            <Layout className="!bg-PrimaryBgWhite-100 px-4">
              <Content className="rounded-lg bg-SecondaryBgWhite-100">{children}</Content>
            </Layout>
          </Layout>
        </Layout>
      )}
    </Fragment>
  )
}
