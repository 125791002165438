import { ReactElement } from 'react'
import { EventLayout } from '../../components/layout/EventLayout'

export default function EventReports(): ReactElement {
  return (
    <EventLayout>
      <div>Reports</div>
    </EventLayout>
  )
}
